<template>
  <el-row>
    <el-col>
      <el-form
        id="change"
        status-icon
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        大类别：
        <el-select clearable v-model="value1" placeholder="请选择"  style="margin-right: 10px">
        <el-option
            v-for="item in bigOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>
      中类别：
        <el-select clearable v-model="value2" placeholder="请选择"  style="margin-right: 10px">
        <el-option
            v-for="item in midOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>
       
      </el-form>
      <el-col>
      <el-form style="margin-top: 10px">
        年：
        <el-select
          clearable
          v-model="year"
          filterable
          placeholder="请选择"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in yearOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        月：
        <el-select
          clearable
          v-model="month"
          filterable
          placeholder="请选择"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in monthOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        日：
        <el-select
          clearable
          v-model="datas"
          filterable
          placeholder="请选择"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in datasOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form>
    </el-col>
    </el-col>
    <el-col>
       <el-upload
        style="margin-top: 10px"
          class="upload-demo"
          action="http://site_treasure.zzmeetluyao.com/api/luyao/keyword/keyword"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="onsuccess"
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :data="paramsData"
          :before-upload="beforeUpload"  
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传excel，大小不能超过50M
          </div>
        </el-upload>
    </el-col>
  </el-row>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";

export default {
  methods: {
    beforeUpload(file){
      this.isLt2k = file.size / 1024  < 51200?'1':'0';        
	if(this.isLt2k==='0') {            
		this.$message({                
			message: '上传文件大小不能超过50M!',                
			type: 'error'            
		});        
	}        
	return this.isLt2k==='1'?true: false;
    },
     createyear() {
      let date = new Date();
      let y = date.getFullYear();
      let sss = {id:y-2, label:(y-2) + '年'};
      this.yearOptions.push(sss);
      let ss = {id:y-1, label:(y-1) + '年'};
      this.yearOptions.push(ss);
      let s = {id:y, label:y + '年'};
      this.yearOptions.push(s);
      let sc = {id:y+1, label:(y+1) + '年'};
      this.yearOptions.push(sc);
      let scc = {id:y+2, label:(y+2) + '年'};
      this.yearOptions.push(scc);
      let sccc = {id:y+3, label:(y+3) + '年'};
      this.yearOptions.push(sccc);
      },
    createDates() {
      for (let i = 1; i <= 12; i++) {
        let date = { id: i, label: i + "月" };
        this.monthOptions.push(date);
      }
    },
      createmonths() {
      for (let i = 1; i <= 31; i++) {
        let date = { id: i, label: i + "日" };
        this.datasOptions.push(date);
      }
    },
    onsuccess(response, file, fileList) {
      if(response.status==200){
        this.$message('上传成功')
      }else{
        this.$message(response.message)
      }
      
    },
    
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    handleClick(row) {
      console.log(row);
    },
     getBigTypeList() {
      this.$api.get(
          "/type/big/list",
          null,
          {
            list: false
          },
          (successRes) => {
            console.log(successRes);
            this.bigOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
  },
  data() {
    return {
      yearOptions: [],
      year: "",
      monthOptions: [],
      month: "",
      datasOptions: [],
      datas: "",
      page: 1,
      record: 10,
      keyword: "",
      type: "",
      midOptions:[],
      bigOptions:[],
      value1:'',
      value2:'',
      fileList: [],
      value: "",
      asearchdata: {},
      userInfo: {},
      updateStatus: 0,
      
    };
  },
   watch: {
    value1: {
      handler(newVal,oldVal) {
        this.newVal = newVal; 
        console.log(this.newVal);
        this.$api.get(
          "/type/getMidTypeByBigTypeId",
          null,
          {
            list: false,
            bigTypeId: this.newVal,

          },
          (successRes) => {
            console.log(successRes);
            this.midOptions = successRes.data;
            // console.log(" type="+this.midOptions)
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
      
      },
    }
  },
  mounted() {
    this.getBigTypeList();
    this.createDates();
    this.createmonths();
    this.createyear();
  },
  computed: {
    paramsData: function () {
      let params = {
         bigTypeId: this.value1,
          midTypeId: this.value2,
          day:this.datas,
          month:this.month,
          year:this.year,
      }
      console.log(params);
      return params
    }
  },
};
</script>